$(document).ready(function(){

    $(window).on('load', function(){
        setSiteMainTopPadding();
        setMastheadHeight();
    });

    $(window).resize(function(){
        setSiteMainTopPadding();
        setMastheadHeight();
    });



    // Shrink Nav on Scroll
    $(window).scroll(function() {
		if ($(this).scrollTop() > 0){
            $('body').addClass("has-header-sticky");
            $('#site_header').addClass("header-sticky");
            // setSiteMainTopPadding();
		}
		else{
            $('body').removeClass("has-header-sticky");
            $('#site_header').removeClass("header-sticky");
		}
        setSiteMainTopPadding();
        setMastheadHeight();
        setTimeout(setSiteMainTopPadding,201); // allow 200 ms for css transitions
        setTimeout(setMastheadHeight,201); // allow 200 ms for css transitions
	});



    // scroll to target ID for jump links
    var offsetSize = $('#site_header').height() + 30;

    if (window.location.hash && $(window.location.hash).length) {

        var anchor = window.location.hash;
        //console.log('hash: ' + anchor);
        $('html, body').animate({
            scrollTop:$(anchor).offset().top - offsetSize
        }, 400);

    }

    // scroll to target ID for jump links
    // https://codepen.io/chriscoyier/pen/dpBMVP
    $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .not('[data-bs-toggle=collapse]')
        .not('[data-bs-toggle=modal]')
        .not('[data-bs-slide-to]')
        .click(function(event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
//                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top - offsetSize
                    }, 1000, function() {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });




    // adjust classes for nested collapsing primary nav
    $(function () {
        $('#nav_primary').on('show.bs.collapse', function() {
            $('.dropdown.nav-link-open').addClass('show');
            $('.dropdown.nav-link-open > .dropdown-toggle ').attr('aria-expanded', 'true');
            $('.dropdown.nav-link-open > .dropdown-menu ').addClass('show');
        });

        $('#nav_primary').on('hide.bs.collapse', function() {
            $('.dropdown').removeClass('show');
            $('.dropdown > .dropdown-toggle ').attr('aria-expanded', 'false');
            $('.dropdown > .dropdown-menu ').removeClass('show');
        });
    });


});







function setSiteMainTopPadding () {
    let headerHeight = $('#site_header').outerHeight();
    document.documentElement.style.setProperty('--club-header-height', headerHeight+'px');
}

function setMastheadHeight () {
    let mastheadHeight = $('.navbar-masthead').outerHeight();
    document.documentElement.style.setProperty('--club-masthead-height', mastheadHeight+'px');
}






/* e-commerce */
(function() {

    let feedbackTimeout = null;

    // category-select.blade.php
    if(document.querySelector('#ecommerce-category-select')) {
        const categories = document.querySelector('#ecommerce-categories');
        if (categories) {
            categories.addEventListener('change', e => {
                if (e.target.value) {
                    window.location.href = '/store/category/' + e.target.value;
                } else {
                    window.location.href = '/store';
                }
            });
        }
    }

    // product.blade.php
    if(document.querySelector('#ecommerce-page-product')) {
        const productOption = document.querySelector('#ecommerce-product-option');
        const productPrice = document.querySelector('#ecommerce-product-price');
        const quantityDesired = document.querySelector('#ecommerce-quantity-desired');
        const addToCart = document.querySelector('#ecommerce-add-to-cart');
        productOption.addEventListener('change', e => {
            if (e.target.value) {
                productPrice.innerHTML = e.target.options[e.target.selectedIndex].getAttribute('data-price-formatted');
                addToCart.disabled = e.disabled;
            } else {
                productPrice.innerHTML = '';
                addToCart.disabled = true;
            }
        });
        addToCart.addEventListener('click', async e => {
            const result = await updateCart('add', productOption.value, quantityDesired.value, '#ecommerce-product-feedback');
        });
    }

    // cart.blade.php
    if(document.querySelector('#ecommerce-page-cart')) {
        const updateCartBtns = document.querySelectorAll('.ecommerce-update-cart');
        const deleteCartBtns = document.querySelectorAll('.ecommerce-delete-product-option');
        const clearCart = document.querySelector('#ecommerce-clear-cart');
        updateCartBtns.forEach(item => {
            const productOptionId = item.getAttribute('data-product-option-id');
            const quantityDesired = document.querySelector(`#ecommerce-value-product-option-id-${productOptionId}`);
            item.addEventListener('click', async e => {
                await updateCart('update', productOptionId, quantityDesired.value, `#ecommerce-feedback-product-option-id-${productOptionId}`);
            });
        });
        deleteCartBtns.forEach(item => {
            const productOptionId = item.getAttribute('data-product-option-id');
            item.addEventListener('click', async e => {
                if(confirm('Remove product from cart?')) {
                    const result = await updateCart('remove', productOptionId, null, `#ecommerce-feedback-product-option-id-${productOptionId}`);
                    if (result) {
                        const rows = document.querySelectorAll('#ecommerce-page-cart .ecommerce-rows');
                        if(rows.length <= 1) {
                            clearCartAll();
                        } else {
                            const row = document.querySelector(`#ecommerce-row-product-option-id-${productOptionId}`);
                            row.innerHTML = '<div style="color:green;margin:2rem 0;">Product deleted</div>';
                            setTimeout(() => {
                                row.remove();
                            }, 1500);
                        }
                    }
                }
            });
        });
        clearCart.addEventListener('click', async e => {
            if (confirm('Clear cart contents?')) {
                const result = await updateCart('clear');
                if (result) {
                    clearCartAll();
                }
            }
        });
    }

    function updateCart(cart_method='add', product_option_id=null, quantity_desired=null, uid) {
        clearFeedback(uid);
        return axios.post('/store/cart', {
            cart_method: cart_method,
            product_option_id: product_option_id,
            quantity_desired: quantity_desired
        }).then(response => {
            console.log(response.data.message);
            if(window.location.pathname === '/store/cart' && response.data.quantity === 1 && response.data.sub_total < 100) {
                location.reload();
                return;
            }
            if(cart_method !== 'remove') {
                setFeedback('success', response.data.message, uid);
            }
            updateSubTotal(response.data.sub_total_formatted);
            updateCartIconQuantity(response.data.quantity_icon_formatted);
            return true;
        }).catch(error => {
            console.log(error.response?.data.message);
            setFeedback('error', error.response?.data.message, uid);
            return false;
        });
    }

    function setFeedback(type, message, uid) {
        const productFeedback = document.querySelector(uid);
        if(productFeedback) {
            clearTimeout(feedbackTimeout);
            productFeedback.style.color = type === 'success' ? 'green' : 'red';
            productFeedback.innerHTML = message;
            feedbackTimeout = setTimeout(() => {
                clearFeedback(uid);
            }, type === 'success' ? 1500 : 5000);
        }
    }

    function clearFeedback(uid) {
        const productFeedback = document.querySelector(uid);
        if(productFeedback) {
            productFeedback.style.color = null;
            productFeedback.innerHTML = null;
        }
    }

    function updateCartIconQuantity(quantity) {
        const cartIconQuantity = document.querySelector('#ecommerce-cart-quantity');
        if(quantity) {
            cartIconQuantity.innerHTML = quantity;
            cartIconQuantity.style.display = 'flex';
        } else {
            cartIconQuantity.innerHTML = '';
            cartIconQuantity.style.display = 'none';
        }
    }

    function updateSubTotal(subTotal) {
        const subTotalElem = document.querySelector('#ecommerce-sub-total');
        if(subTotalElem) {
            subTotalElem.innerHTML = subTotal;
        }
    }

    function clearCartAll() {
        const pageCart = document.querySelector('#ecommerce-page-cart');
        const alerts = document.querySelector('.alerts');
        alerts.innerHTML = '';
        pageCart.innerHTML = '<p>Cart Empty</p><a href="/store">Start Shopping!</a>';
        updateCartIconQuantity(0);
    }

})()
